.SocialIconsBody {
   display: flex;
   padding: 2px;
   width: 4.489%;
   gap: 10px;
   align-items: center;
}

.isMobile {
   width: 10%;
   padding: 5px;
   gap: 15px;
}

.SocialStyle {
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   background-color: #265fff;
   border-radius: 7.2px;
   width: 25px;
   height: 25px;
}