.FooterBody {
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    padding-right: 90px;
    padding-left: 90px;
    min-height: 208px;
    max-height: 208px;
    background: url('../../images/FooterFull.png') no-repeat;
    background-size: cover;
    background-position: top;
    overflow: hidden;
}

.LinksFooter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5%;
    padding: 5px;
    margin-top: 1%;
    min-width: 65%;
}

.CopyRightFooter {
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    width: 83%;
    gap: 0 7%;
    align-self: center;
    align-items: center;
    color: #4C5981;
    font-size: 12px;
    font-weight: 600;
}

.ImgFooter {
    display: flex;
    max-width: 27%;
    justify-content: right;
}
.ButtonFlag{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.TextFlag{
    color: #070A12;
}

.Flag {
        display: flex;
        padding: 2px;
        width: 4.489%;
        justify-content: center;
        align-items: center;
}