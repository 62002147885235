.MainBody {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    padding-top: 2px;
    padding-right: 2px;
    padding-left: 2px;
}

.MainMobile {
    height: 72%;
    max-height: 72%;
}

.ButtonNavigationLeft, 
.ButtonNavigationRight{
    display: flex;
    position: absolute;
    top: 40%
}
.ButtonNavigationLeft{
    left: 15px;
}
.ButtonNavigationRight{
    right: 15px;
}

.MobileNavegation {
    top: 47%
}