.AppScreen{
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
}

.AppBody {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
}

