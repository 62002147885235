.Footer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 22%;
    padding-top: 19px;
    padding-left: 24px;
    padding-right: 24px;
    background: url('../../images/sss.png') no-repeat;
    background-size:cover;
    background-position: top;
    overflow: hidden;
}

.Opened {
    height: 48%;
}

.MenuButton {
    display: flex;
    gap: 13px;
    height: fit-content;
    align-items: center;
}

.LogoFooter {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}