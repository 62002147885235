.FormInputBody{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 25px;
}

.JustInputs {
    display: flex;
    flex-direction: column;
    gap: 13.5px;
}

.ProgressBarInputFlow{
    display: flex;
    width: 100%;
}

.FormItemsInputFlow {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 60px;
    padding-top: 20px;
    padding-bottom: 40px;
}
.InputButtons {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    gap: 20px; 
}

.IconLogoAccountInput{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    }