.Steps {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.StepMain {
    width: 100%;
    height: 100%;
    background-color: #F5F4F6;
    background-image: url('../../images/DemonstrationBG.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
}
.MobileStepMain{
    background-color: #F5F4F6;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
}

.Card {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url('../../images/DashboardRunning.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 51%;

}

.CardMobile {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.MobileList {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    height: 100%;
    padding: 10px;
    padding-top: 30%;
    font-size: 20px;
}

.LogoMain {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    height: 8%;
    top: 5%;
    width: 100%;
    padding-right: 5%;
    justify-content: center;

}


.LogoMain img {
    max-width: 80%;
}

.desktop {
    box-sizing: border-box;
    justify-content: space-around;
}
.leftBox {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 2%;
    padding-left: 6%;
    padding-right: 5%;
    width: 42%;
}
.MobileSteps {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-around;
    text-align: center;
    height: 100%;
    width: 100%;
}

.leftBox  h1 {
    margin-top: 0;
    font-size: 72px;
    font-weight: 900;
}

.leftBox h2 {
    font-size: 24px;
    font-weight: 700;
    color: #265fff;
}

.leftBox h3 {
    font-size: 16px;
    padding-bottom: 25px;
    font-weight: 400;
    color: #070A12;
}

.MobileSteps h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 900;
}

.MobileSteps h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: #265fff;
    padding-left: 10px;
    padding-right: 10px
}

.MobileSteps h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #070A12;
}

.ImagesStep2 {
    display: flex;
    width: 58%;
    height: 74vh;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}