.Beta {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('../../images/BETA.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.MobileBeta {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('../../images/MobileBETA.png');
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
}

.Beta .ButtonBeta{
    display: flex;
    position: absolute;
    top:7%;
    right:18.0%;
    width: 320px;
    
}
.MobileBeta .ButtonBeta{
    display: flex;
    position: absolute;
    top:14%;
    width: 200px;
    
}
.ButtonBeta:hover {
    display: block;
}
.BlueText {
    font-size: 19px;
    fill: #265FFF;
  }

.C2ABallon{
    font-size: 22px;
    fill: #265FFF;
    cursor: pointer;
  }

.C2ABallon:hover {
    font-size: 26px;
}


  