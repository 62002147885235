.BoxMainBody{
    display: flex;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.BoxMainMObile{
    padding:5px;
    overflow: scroll;
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 60%;
}

.ImageBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
}

.BoxMainBody .TextBox{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px 60px;
    overflow: auto;
}

.BoxMainBody .TextBox h1 {
    font-size: 72px;
    font-weight: 900;
    margin: 0;
    padding: 0;
}
.BoxMainBody .TextBox h2 {
    font-size: 16px;
    font-weight: 900;
    margin: 0;
    padding: 0px 15px;
}

.ItemBox {
    padding: 10px;
}
.BoxMainBody .ItemBox h2 {
    font-size: 24px;
    font-weight: 700;
    color: #265fff;
    padding: 5px;
    margin: 0;
}

.BoxMainBody .ItemBox h3 {
    font-size: 14px;
    font-weight: 400;
    color: #070A12;
    text-align: justify;
    padding: 5px;
    margin: 0;
}


.BoxMainMObile .TextBox h1 {
    margin: 0;
    padding: 0;
}
.BoxMainMObile .TextBox h2 {
    font-size: 14px;
    font-weight: 900;
    margin: 0;
    padding: 15px;
}

.ItemBox {
    padding: 10px;
}
.BoxMainMObile .ItemBox h2 {
    font-size: 20px;
    color: #265fff;
    padding: 5px;
    margin: 0;
}

.BoxMainMObile .ItemBox h3 {
    
    font-size: 14px;
    color: #070A12;
    text-align: justify;
    padding: 5px;
    margin: 0;
}
