.CardsPromotionsBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #FAFAFA;
    box-shadow: 0px 2px 12px rgba(21, 0, 53, 0.2);
    border-radius: 24px;
    margin-left: 22px;
    margin-right: 22px;
    gap: 24px;
}

.HeaderCards {
    display: flex;
    box-sizing: border-box;
    height: 70px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.EffectHeaderCard {
    display: flex;
    width: 21px;
    height: 21px;
    padding: 5px;
    transform: rotate(45deg);
    bottom: -10px;
    position: relative;
}


.ButtonCards {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
}
.TextItemsCard{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ItemsValueCards {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-between;
    align-items: center;
}

.LineItemsCard {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.ValuesItemCard {
    display: flex;
}