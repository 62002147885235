.ContactBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2px;
    padding-right: 2px;
    padding-left: 2px;
}

.ContactSocial{
    display:flex; 
    flex-direction:column; 
    justify-content: center;
    gap: 20px;
}



