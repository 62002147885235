.PopupFadeAlert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.PopupModalAlert {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 100%;
  height: 100%;
  max-width: 476px;
  max-height: 376px;
  background-image: url('./image/Cloud.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size:auto;
}
.AlertMobile {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5%;
  padding-bottom: 10%;
}

.BackgroundAlert {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.AlertBody {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 5px;
  padding-top: 10%;
  padding-bottom: 10%;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.HeaderAlert,
.MainAlert {
  display: flex;
  box-sizing: border-box;
  padding: 2px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MainAlert {
}


.IconAlert {
  margin-right: 70px;
  margin-top: 35px;
}

.ButtonsAlert {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}