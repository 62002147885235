.ProgressBorder {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
}

.Progress {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.Progress-Icon {
    display: flex;
    justify-content: center;
    height: 45px;
    width: 100%;
}
.Progress-Icon img {
    height: 45px;
    width: 45px;
}
.Progress-Text {
    font-size: 12px;
}
.Progress-Bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}
.Progress-Point {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 16px;
    min-width: 16px;
    max-height: 16px;
    max-width: 16px;
    border: #d7dceb solid 2px;
    background-color:  white;
    border-radius: 4.9px;
}
.Progress-Line {
    height: 6px;
    width: 100%;
}
.LineUnchecked {
    background-color: #d7dceb;
}
.LineChecking {
    
    border-color: #265fff;
}
.LineChecked {
    background-color: #265fff;
    border-color: #265fff;
}



.Square {
    min-height: 10px;
    min-width: 10px;;
    max-height: 10px;
    max-width: 10px;
    background-color: #265fff;
    border-radius: 2.9px;
}