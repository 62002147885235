@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700;800;900&display=swap');
html{
  margin: 0px; 
  padding: 0px;
  height: auto;
  width: auto;
  overflow: hidden;
}
body {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  width: auto;
  background-color: #F5F4F6;
  font-family:  -apple-system, BlinkMacSystemFont, 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* A altura só é vista quando a rolagem é horizontal */
}

::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #7499FF;
  border-radius: 9px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
