.Email,
.Selectable,
.Back,
.Cancel,
.DefaultColor,
.SiteConfirm,
.SiteCancel,
.SiteNavigation {
  cursor: pointer;
}
button {
  padding: 0;
  margin: 0;
}

/*Size */
.SiteNavigation {
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 80px;
}
.mobileNavigation {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.DefaultButton {
  width: 140px;
  height: 40px;
  border-radius: 12.36px;
  font-size: 16px;
  font-weight: 600;
}

.Split {
  width: 155px;
  height: 28px;
  border-radius: 9.27px;
  font-size: 12px;
}

.Short {
  width: 70px;
  min-height: 30px;
  border-radius: 9.27px;
  font-size: 12px;
}

.Small {
  width: 130px;
  height: 30px;
  border-radius: 9.27px;
  font-size: 14px;
  font-weight: 600;
}

.Medium {
  width: 210px;
  height: 40px;
  border-radius: 12.36px;
  font-size: 16px;
}

.Large {
  width: 340px;
  height: 50px;
  border-radius: 15.45px;
  font-size: 16px;
  font-weight: 600;
}
.MenuMobile {
  width: 140px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}
.MenuMobileTop {
  width: 140px;
  height: 40px;
  
  border-radius:  12.36px 12.36px 0 0;
  font-size: 16px;
  font-weight: 600;
}
.MenuMobileDown {
  width: 140px;
  height: 40px;
  
  border-radius: 0 0 12.36px 12.36px ;
  font-size: 16px;
  font-weight: 600;
}


/*Type Colors*/
.Navigation {
  background: #F0f3fB;
  border-radius: 9.27px;
  border: 2px solid #F0f3fB;
  color: #265fff;
}

.Navigation:hover {
  background: #DFE4F1;
}

.SiteCancel {
  color: #265fff;
  background-color: #F5F4F6;
  border: 3px solid #265FFF;
}

.SiteConfirm {
  color: #ffffff;
  background-color: #265FFF;
  border: 3px solid #265FFF;
}

.DefaultColor {
  color: #ffffff;
  background-color: #265FFF;
  border: 3px solid #265FFF;
}

.Cancel {
  background-color: #ffffff;
  color: #265FFF;
  border: 2px solid #265FFF
}
.CancelB {
  background-color: #F5F4F6;
  color: #265FFF;

  border: 2px solid #265FFF;}

.Back {
  background-color: #D9E3F4;
  color: #265FFF;
  border: 2px solid #D9E3F4;
}

.Selectable {
  background-color: #265FFF;
  color: #ffffff;
  border: 3px solid #265FFF;
}

.Fixed {
  color: #353535;
  background-color: #ffffff;
  border: 1px solid #353535;
}

.Email {
  color: #353535;
  background-color: #ffffff;
  border: 1.3px solid #D8D8D8;
}


/*Hover*/
/*.SiteNavigation:hover {
  box-shadow: inset 0px 0px 5px 0px #262642;
}*/

.SiteConfirm:hover {
  background-color: #F5F4F6;
  color: #265FFF;
}

.SiteCancel:hover {
  background-color: #265FFF;
  color: #F5F4F6;
}

.DefaultColor:hover {
  background-color: transparent;
  color: #265FFF;
}

.Back:hover {
  background-color: #ffffff;
  color: #265FFF;
  border: 2px solid #265FFF;
}

.Cancel:hover {
  color: #ffffff;
}

.CancelB:hover {
  background-color: #265FFF;
  color: #ffffff;
  border: 2px solid #265FFF;
}

button[disabled]:hover {
  border: 0.1rem solid;
  background-color: #D8D8D8;
  color: #262642;
}