.TermsPage {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    justify-content: center;
    overflow: auto;
    color: #39496D;
}

.mobile {
    
    margin-bottom: 130px;
}

.TermPage-contents {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    padding-top: 50px;
    gap: 50px;
}

.mobile .TermPage-contents {
    width: 90%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.mobile .content {
    gap: 22px;
}
.content-itens {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-left: 40px;
}

.mobile .content-itens {
    padding-left: 10px;
}

.content-itens span {
    padding-left: 40px;
    font-size: 16px; /* Tamanho do ponto */
}

.mobile .content-itens span {
    padding-left: 10px;
    font-size: 16px; /* Tamanho do ponto */
}

.content-itens span::before {
    content: '•'; /* Ponto Unicode */
    color: black; /* Cor do ponto */
    margin-right: 8px; /* Espaço entre o ponto e o texto */
    font-size: 1.2em; /* Tamanho do ponto */
    vertical-align: middle; /* Alinhamento vertical */
}