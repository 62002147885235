.HeaderBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;    
    padding-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(21, 0, 53, 0.2);
}

