.FormComponentBody{
    display: flex;
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 42px;
    padding-right: 42px;
    height: 760px;
    width: 412px;
    flex-direction: column;
    justify-content: space-between;
    background: #FAFAFA;
    box-shadow: 0px 2px 12px rgba(21, 0, 53, 0.15);
    border-radius: 20.36px;
}

.Mobile {
    display: flex;
    box-sizing: border-box;
    padding: 40px;
    padding-top: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #FAFAFA;
}