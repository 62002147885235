.AccountPreviewBody {
    display: flex;
    background: url('../../../images/rocktAccount.png') no-repeat;
    width: 100%;
    height: 100%;
    padding: 50px;
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
}
.AccountPreviewMobile {
    display: flex;
    padding: 25px;
    padding-top: 10px;
    box-sizing: border-box;
    padding-bottom: 100px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
}
.AccountPreviewBody .LogoAccount {
    display: flex;
    padding: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.AccountPreviewMobile .LogoAccount {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.TitleAccount {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    width: 60%;
}

.TitleAccount h1 {
    margin-top: 0;
    padding: 0;
}

.AccountPreviewMobile .TitleAccount {
    margin-top: 0px;
    width: 100%;
}

.AccountPreviewBody h1 {
    color: #265FFF;
    font-size:80px;
    font-weight: 900;
    margin-bottom: 0;
}

.AccountPreviewMobile h1 {
    font-size: 40px;
    text-align: center;
}
.AccountPreviewBody .MessageAccount {
    display: flex;
    width: 60%;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;
    height: 200px;
    margin-top: 9px;
}
.AccountPreviewMobile .MessageAccount {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0;
    height: fit-content;
    margin-top: 9px;
}

.AccountPreviewMobile h2 {
    font-size: 20px;
    text-align: center;
    color: #070A12;
}
.AccountPreviewBody h2 {
    font-size: 32px;
    font-weight: 900;
    color: #070A12;
}

.AccountPreviewBody .ButtonFlow {
    display: flex;
    width: 100%;
    margin-top: 35px;
}
.AccountPreviewMobile .ButtonFlow {
    display: flex;
    width: 100%;
    padding-bottom: 25px;
    justify-content: center;
}
.AccountPreviewBody .IconLogoAccount {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
}
