

.ToastBody {
display: flex;
width: 408px;
max-width: 100%;
height: 64px;
background-color: #FAFAFA;
border-radius: 19.760000228881836px;
box-shadow: 0px 4px 6px 0px #15003540;
margin-bottom: 10px;
}

.iconToast {
display: flex;
box-sizing: border-box;
justify-content: center;
align-items: center;
border-top-left-radius: 19.760000228881836px;
border-bottom-left-radius: 19.760000228881836px;
width: 72px;
padding: 5px;
}

.messageToast {
display: flex;
box-sizing: border-box;
justify-content: center;
align-items: center;
padding: 10px 10px 10px 15px;
font-size: 14px;
font-weight: 400;
text-align: left;
}

.errorToast{
background-color: #F24A46; 
}

.successToast{
background-color:#265FFF
}

.warningToast{
    background-color:#FF7E3E
}
