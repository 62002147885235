.TextAnimeBody{
    display: flex;
    position: absolute;
    flex-direction: column;
    bottom: 15%;
    right: 0%;
    height: 62px;
    width: 27%;
    padding: 2px;
}
.TitleAnime{
    height: 31px;
    font-size: 26px;
    font-weight: 900;
    color:#265FFF;
    text-shadow: 0px 0px 5px 0px white;
}
.TextStatic {
    position: relative;
    animation: slide-in 1s forwards;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
