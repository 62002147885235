.SignatureBody {
    display: flex;
    flex-direction: row;
    padding-top:30px;
    padding-left: 45px;
    padding-right: 45px;
}

.LeftSignature{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 45%;
    padding-top: 20px;
    padding-left: 45px;
    height: 74vh;
   
}

.TitleSignature{
    display: flex;
    flex-direction: column;
}

.RightSignature{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 55%;
    padding-top: 20px;
    padding-right: 45px;
}

.ImageLeftSignature{
    display: flex;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 40px;
    height: 59vh;
}