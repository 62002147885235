
.PriceCards {
    display: flex;
    height: 77px;
    padding: 5px;
    justify-content: center;
    color: #39496D;
    gap: 6px;
}

.RefPrice {
    display: flex;
    margin-top: 15px;
    font-size: 21px;
    font-weight: 400;
}

.ValuePrice {
    display: flex;
    font-size: 64px;
    font-weight: 700;
}

.DuoValuesPrice {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}