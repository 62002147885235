.AccountFlowBody {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 110px;
    padding-top:45px;
    padding-bottom:45px;
    background: url('../../../images/SiginTrial.png') no-repeat;
}

.MessageThanks{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AccountFlowMobile {
    justify-content: center;
    align-items: center;
    padding: 0px;
    min-height: 100%;
    min-height: 100%;
    background:none;
    background-color: #fafafa;
}

.TitleAccount {
    display: flex;
    margin-top: 71.5px;
}

.TitleAccount h1 {
    color: #265FFF;
    font-size: 72px;
    font-weight: 900;
}

.MessageAccount {
    display: flex;
    width: 60%;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;
    height: 200px;
    margin-top: 9px;
}

.MessageAccount h2 {
    color: #070A12;
}

.RightAccount {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    height: 760px;
    width: 100%;
}

.RightAccount .MobileAccount {
    height: 100%;
}

.ButtonFlow {
    display: flex;
    width: 100%;
    margin-top: 35px;
   
}
.LogoAccount{
    padding-top: 15px;
}
.IconLogoAccount {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
}