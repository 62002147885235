.About{
    display: flex;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    flex-direction: column;
    overflow: hidden;
}



