.ListLinksBody{
    display: flex;
    width: 157px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    font-size: 12px;
    gap: 6px;
 }
 .mobileListLink {
   gap: 8px;
 }
 .LinksItems{
   cursor: pointer;
}
.LinksItems:hover{
   font-weight: bold;
}
 .TitleLinks{
    margin-bottom: 14px;
    display:flex;
    color: #265fff;
    font-weight: bold;
    font-size: 14px;
 }

.mobileItemLink {
   color: #070A12;
   font-size: 22px;
   font-weight: 600;
}

.mobileBetaLink {
   color: #265fff;
   font-size: 22px;
   font-weight: 700;
   
}