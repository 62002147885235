.InputLabel {
    background-color: #EDF0F4;
    width: 100%;
    font-weight: 400;
    color: #070A12;
    border-radius: 10px;
}
.InputBox {
    display: flex;
    justify-content: center;
    position: relative;
}

.InputBox input:focus{
    background-color: #E1E5EA;
    outline: #265FFF 2px solid;
}

.InputBox i {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    right: 10px;
    pointer-events: none;
}
