.ToastAlertContainer {
    display: flex;
    box-sizing: border-box;
    position: absolute;
    flex-direction: column-reverse;
    align-items: flex-end;
    width: 100%;
    top: 0px;
    padding: 5px;
    overflow: hidden;
    z-index: 110;
    
}

.ToastAnime  {
    display: flex;
    position: relative;
    max-width: 100%;
    animation: slide-in 1s forwards;
   
  }

  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
