.InputBox{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.InputLabel {
    align-self: center;
    text-align: left;
    margin-left: 5px;
    width: 100%;
    border: unset;
}

.InputDeviceBox {
    align-self: center;
    text-align: left;
    margin-left: 5px;
    width: 100%;
    border-radius: 10px;
    height: 45px;
    border: unset;
    background-color: #d0daea;
    border-bottom: 2px solid #a8a8a8;
}

.InputEventName {
    display: flex;
    align-self: center;
    text-align: left;
    margin-left: 5px;
    border-radius: 10px;
    height: 45px;
    border: unset;
    background-color: #ffffff;
    border-bottom: 2px solid #a8a8a8;
}
