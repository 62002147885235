.FormOptionsBody {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.FormItemsFlow {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    min-height: 403px;
    max-height: 403px;
    gap: 13.5px;
}

.ItemSelectFlow {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 314px;
    min-height: 70px;
    max-height: 70px;
    background: #EDF0F4;
    border: #EDF0F4 2px solid;
    border-radius: 12.36px;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    padding: 30px;

}
.ItemSelectFlow:hover{
    border-color: #265FFF;
    background-color: #E1E5EA;
}
.ItemLabel{
    display: flex;
    width: 100px;
    text-align: left;
}
.IconSelectFlow{
    display: flex;
    margin-left: 31px;
    margin-right: 32px;
}
.ProgressBarFlow{
    display: flex;
    width: 100%;
    margin-top: 35px;
}


.IconLogoAccount{
display: flex;
margin-top:22px;
}
