.SendEmailEventsBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.TitleSendEmail{
    display: flex;
    width: 796px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    background-color: #f4f4f4;
    border-radius: 9.27px;
    height: 40px;
    align-content: center;
}

.SendToEmails{
    display: flex;
    flex-wrap: wrap;
    width: 806px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-radius: 9.27px;
    min-height: 40px;
    max-height: 130px;
    align-content: center;
    gap: 5px;
}
.SubjectSendEmail{
    display: flex;
    width: 796px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    background-color: #f4f4f4;
    border-radius: 9.27px;
    height: 40px;
    justify-content: left;
}
.TextAreaForm{
    margin-top: 10px;
    font-family: 'Inter';
    font-size: 14px;
    resize: none;
    width: 810px;
    height: 255px;
    border-radius: 9.27px;
}
textarea:focus{
        outline: none;
        border:1px solid #265fff;
}
